<template>
  <div>
    <el-page-header @back="goBack" content="主题修改页面"></el-page-header>
    <div class="new">
      <el-form label-width="80px">
        <el-form-item label="主题图片">
          <div class="button-block">
            <img :src="api+img" width="300px" />
            <!-- <el-button style="position:relative" type="primary" @click="selectImg">
              选择图片
              <label for="selImg"></label>
            </el-button> -->
            <!-- <input type="file" id="selImg" ref="img" @change="selectImg" /> -->
          </div>
        </el-form-item>
        <el-form-item label="主题标题">
          <el-input v-model="title" class="text"></el-input>
        </el-form-item>
        <el-form-item label="主题内容">
          <el-input v-model="content" class="text" type="textarea" rows="5"></el-input>
        </el-form-item>
        <el-form-item label="创作时间">
          <el-input v-model="createTime" class="text" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addBlog(id)">立即修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
//加载数据库
import { QUERYED, UPDATE, DELETE, INSERT } from "@/services/dao.js";
import { showTimeFormat } from "@/js/common/index.js";
export default {
  data() {
    return {
      api: this.api.LoginURL.concat(),
      img: "",
      title: "",
      content: "",
      createTime: ""
    };
  },
  created() {
    // this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    ////页面初始化加载
    async init() {
      let data = await QUERYED(
        "post",
        "",
        "TtTheme(where: {id:  {_eq: " +
          this.$route.query.id +
          "}}) { id img  title  content createTime   }"
      );
      this.id = data.data.TtTheme[0].id;
      this.img = data.data.TtTheme[0].img;
      this.title = data.data.TtTheme[0].title;
      this.content = data.data.TtTheme[0].content;
      this.createTime = showTimeFormat(data.data.TtTheme[0].createTime);
    },
    goBack() {
      this.$router.go(-1);
    },
    // 点击修改主题的确定按钮（确定新增信息） 将所增信息提交给后台
    async addBlog(id) {
      this.id = id;
      let data = await UPDATE(
        "POST",
        "",
        'update_TtTheme(_set:{content:"' +
          this.content +
          '",img: "' +
          this.img +
          '", title: "' +
          this.title +
          '",createTime:"' +
          this.createTime +
          '"}, where: {id: {_eq:' +
          this.id +
          "}}) {  affected_rows }"
      );
      console.log(data);
      //alert(data)
      if (data.data.update_TtTheme.affected_rows > 0) {
        //alert("修改成功!");
        this.$message({
          message: "修改成功!",
          type: "success"
        });
        this.$router.push("/topics");
      } else {
        //alert("修改失败！");
        this.$message({
          message: "修改失败！",
          type: "warning"
        });
      }
    },
    selectImg(e) {
      let inputDOM = this.$refs.img;
      if (!inputDOM.files[0]) {
        return;
      }
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        console.log("inputDOM.files[0]:", inputDOM.files[0]);
        console.log(
          "URL.createObjectURL(inputDOM.files[0]): ",
          URL.createObjectURL(inputDOM.files[0])
        );
        this.img = URL.createObjectURL(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: "格式不正确,请上传图片文件",
          type: "warning"
        });
      }
    }
  }
};
</script>
<style scoped>
.new {
  margin-top: 20px;
}
.text {
  width: 500px;
}
.button-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
#selImg {
  display: none;
}
</style>
